import {
  lengthGreaterOrEqualTo,
  required,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { EXCLUDED_POSTCODES_REGEX, POSTCODE_REGEX } from 'helpers/regexes';
import { AddressDetails } from 'state/formData/customerDetails';
import { CsErrorsMissingOnly } from 'types/contentStack';

type CsAddressErrorMessages = {
  csPetAboutYouPostcodeLookup: {
    postcode: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_location: string;
        invalid_format: string;
        not_verified: string;
      };
    };
    address_select: CsErrorsMissingOnly;
  };
  csPetAboutYouManualAddress: {
    flat_name_or_number: {
      error_messages: {
        missing_house_and_flat_details: string;
      };
    };
    house_name_or_number: {
      error_messages: {
        missing_house_and_flat_details: string;
      };
    };
    street: CsErrorsMissingOnly;
    town: CsErrorsMissingOnly;
    postcode: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_format: string;
        invalid_location: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYouPostcodeLookup {
      postcode {
        error_messages {
          missing
          too_short
          invalid_location
          invalid_format
          not_verified
          not_found
          api_unavailable
        }
      }
      address_select {
        error_messages {
          missing
        }
      }
    }
    csPetAboutYouManualAddress {
      flat_name_or_number {
        error_messages {
          missing_house_and_flat_details
        }
      }
      house_name_or_number {
        error_messages {
          missing_house_and_flat_details
        }
      }
      street {
        error_messages {
          missing
        }
      }
      town {
        error_messages {
          missing
        }
      }
      postcode {
        error_messages {
          missing
          too_short
          invalid_format
          invalid_location
        }
      }
    }
  }
`;

const useAddressRules = (): ValidationRules<AddressDetails> => {
  const errorMessages = useStaticQuery<CsAddressErrorMessages>(query);

  return {
    postcodeLookup: validateIf((data) => !data.isManualAddress, [
      {
        validityCondition: (value) => !value.lookupKey.match(EXCLUDED_POSTCODES_REGEX),
        errorMessage:
          errorMessages.csPetAboutYouPostcodeLookup.postcode.error_messages
            .invalid_location,
      },
      {
        validityCondition: (value) => !!value.lookupKey,
        errorMessage:
          errorMessages.csPetAboutYouPostcodeLookup.postcode.error_messages.missing,
      },
      {
        validityCondition: (value) =>
          value.lookupKey !== undefined && value.lookupKey.length >= 5,
        errorMessage:
          errorMessages.csPetAboutYouPostcodeLookup.postcode.error_messages.too_short,
      },
      {
        validityCondition: (value) => !!value.lookupKey.match(POSTCODE_REGEX),
        errorMessage:
          errorMessages.csPetAboutYouPostcodeLookup.postcode.error_messages
            .invalid_format,
      },
      {
        validityCondition: (_, data) =>
          data.usePreviousAddress || !!data.postcodeLookup.data,
        errorMessage:
          errorMessages.csPetAboutYouPostcodeLookup.postcode.error_messages.not_verified,
        onlyValidateAfterSubmission: true,
      },
    ]),
    address: validateIf(
      (data) =>
        data.usePreviousAddress || (!!data.postcodeLookup.data && !data.isManualAddress),
      [
        required(
          errorMessages.csPetAboutYouPostcodeLookup.address_select.error_messages.missing
        ),
      ]
    ),
    flatNameOrNumber: validateIf((data) => data.isManualAddress, [
      {
        validityCondition: (v, data) => !!(v || data.houseNameOrNumber),
        errorMessage:
          errorMessages.csPetAboutYouManualAddress.flat_name_or_number.error_messages
            .missing_house_and_flat_details,
      },
    ]),
    houseNameOrNumber: validateIf((data) => data.isManualAddress, [
      {
        validityCondition: (v, data) => !!(v || data.flatNameOrNumber),
        errorMessage:
          errorMessages.csPetAboutYouManualAddress.house_name_or_number.error_messages
            .missing_house_and_flat_details,
      },
    ]),
    street: validateIf((data) => data.isManualAddress, [
      required(errorMessages.csPetAboutYouManualAddress.street.error_messages.missing),
    ]),
    town: validateIf((data) => data.isManualAddress, [
      required(errorMessages.csPetAboutYouManualAddress.town.error_messages.missing),
    ]),
    postcode: validateIf((data) => data.isManualAddress, [
      required(errorMessages.csPetAboutYouManualAddress.postcode.error_messages.missing),
      lengthGreaterOrEqualTo(
        5,
        errorMessages.csPetAboutYouManualAddress.postcode.error_messages.too_short
      ),
      {
        validityCondition: (value) => !!value.match(POSTCODE_REGEX),
        errorMessage:
          errorMessages.csPetAboutYouManualAddress.postcode.error_messages.invalid_format,
      },
      {
        validityCondition: (value) => !value.match(EXCLUDED_POSTCODES_REGEX),
        errorMessage:
          errorMessages.csPetAboutYouManualAddress.postcode.error_messages
            .invalid_location,
      },
    ]),
  };
};

export const allowAddressLookup = (addressDetails: AddressDetails): boolean => {
  return !!addressDetails.postcodeLookup.lookupKey.match(POSTCODE_REGEX);
};

export default useAddressRules;
