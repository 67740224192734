import { graphql, navigate } from 'gatsby';
import React from 'react';
import LoadQuoteInProgressWrapper from 'components/ApiRequestWrapper/LoadQuoteInProgressWrapper';
import HeroBanner from 'components/Header/HeroBanner';
import Layout from 'components/Layout';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import AboutYouForm from 'forms/AboutYouForm';
import { PageTitle } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { CsHero } from 'types/contentStack';

type AboutYouProps = {
  data: {
    csPetAboutYou: {
      meta_title: string;
      hero: CsHero;
    };
  };
  location: Location;
};

export const query = graphql`
  query {
    csPetAboutYou {
      meta_title
      hero {
        heading
        subheading
      }
    }
  }
`;

const AboutYou: React.FC<AboutYouProps> = ({
  data: {
    csPetAboutYou: { hero, meta_title },
  },
  location,
}) => {
  const moveNext = (): void => {
    navigate(quoteAndBuyRoutes.aboutYourPolicy);
  };

  const moveBack = (): void => {
    navigate(quoteAndBuyRoutes.aboutYourPet);
  };

  usePageTracking(meta_title);

  return (
    <LoadQuoteInProgressWrapper location={location}>
      <Layout
        pageTitle={PageTitle.AboutYou}
        currentStep={QuoteAndBuyStep.AboutYou}
        metaTitle={meta_title}>
        <HeroBanner heading={hero.heading} subheading={hero.subheading} />
        <AboutYouForm moveNext={moveNext} moveBack={moveBack} />
      </Layout>
    </LoadQuoteInProgressWrapper>
  );
};

export default AboutYou;
