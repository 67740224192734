import SelectInput, {
  SelectInputProps,
} from '@rsa-digital/evo-shared-components/components/Form/SelectInput';
import { InputComponent } from '@rsa-digital/evo-shared-components/components/Form/inputTypes';
import React from 'react';

const ThemedSelectInput: InputComponent<SelectInputProps> = ({
  id,
  options,
  placeholder,
  value,
  loading,
  ...rest
}) => {
  return (
    <SelectInput
      {...rest}
      id={id}
      options={options}
      placeholder={placeholder}
      value={value}
      loading={loading}
    />
  );
};

export default ThemedSelectInput;
