import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import { addLeadingZerosToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  QuestionWithPlaceholderField,
  StringField,
} from '@rsa-digital/evo-shared-components/helpers/forms/types';
import useValidationWithWarnings from '@rsa-digital/evo-shared-components/helpers/forms/useValidationWithWarnings';
import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactElement } from 'react';
import FormFooter from 'components/FormFooter';
import { NameInput } from 'components/NameInput/styles';
import QuestionField from 'components/QuestionField';
import SelectInput from 'components/SelectInput';
import {
  PageTitle,
  trackFieldError,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackFormTextFieldFocus,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { filterTitleOptions } from 'helpers/filterTitleOptions';
import { scrollAndTrackError } from 'helpers/forms';
import { INPUT_REGEX_NAME, INPUT_REGEX_NAME_SPECIAL_CHARS } from 'helpers/inputRegexes';
import { capitaliseCharacterAfterHyphenAndSpace } from 'helpers/stringHelpers';
import { CustomerDetails, useCustomerDetails } from 'state/formData/customerDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import { ReferenceDataOption } from 'types/referenceData';
import AddressForm from './AddressForm';
import useAddressRules from './AddressForm/validation';
import useAboutYouQuestions, { AboutYouQuestions } from './questions';
import useAboutYouRules from './validation';

type AboutYouFormProps = {
  moveNext: () => void;
  moveBack: () => void;
};

type AboutYouFormData = {
  csPetAboutYou: {
    next_button_text: string;
  };
};

export const query = graphql`
  query {
    csPetAboutYou {
      next_button_text
    }
  }
`;

const AboutYouForm: React.FC<AboutYouFormProps> = ({ moveNext, moveBack }) => {
  const {
    csPetAboutYou: { next_button_text },
  } = useStaticQuery<AboutYouFormData>(query);

  const questions = useAboutYouQuestions();
  const [customerDetails, updateCustomerDetails] = useCustomerDetails();

  const customerRules = useAboutYouRules();
  const addressRules = useAddressRules();

  const titlesRefData = useReferenceData('titles')?.titles ?? [];
  const filteredTitlesRefData = filterTitleOptions(
    titlesRefData,
    customerDetails.customerTitle
  );

  const {
    getError,
    getWarning,
    showValidation,
    validateOnSubmit,
  } = useValidationWithWarnings(
    customerDetails,
    {
      ...customerRules.errors,
      ...addressRules,
    },
    customerRules.warnings,
    trackFieldError
  );

  const renderRefDataQuestion = (
    id: QuestionWithPlaceholderField<AboutYouQuestions> & StringField<CustomerDetails>,
    options: ReferenceDataOption[] | undefined,
    isPii?: boolean,
    analyticsDescription?: string
  ): ReactElement => (
    <QuestionField
      question={questions[id]}
      errorText={getError(id)}
      warningText={getWarning(id)}>
      <SelectInput
        id={id}
        value={customerDetails[id]}
        options={options ?? []}
        placeholder={questions[id].placeholder}
        onChange={(e) => {
          trackFormDropdownSelect(
            analyticsDescription || id,
            isPii ? undefined : e.target.value
          );
          updateCustomerDetails({ [id]: e.target.value });
        }}
        onBlur={() => showValidation(id)}
        onFocus={trackFormDropdownFocus(analyticsDescription || id, PageTitle.AboutYou)}
        loading={!options}
      />
    </QuestionField>
  );

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      {renderRefDataQuestion(
        'customerTitle',
        filteredTitlesRefData,
        false,
        'Customer title'
      )}
      <QuestionField
        question={questions.customerFirstName}
        errorText={getError('customerFirstName')}>
        <NameInput
          id="customerFirstName"
          placeholder={questions.customerFirstName.placeholder}
          maxLength={40}
          value={customerDetails.customerFirstName}
          onChange={(e) => {
            if (
              e.target.value.match(INPUT_REGEX_NAME) ||
              // Users from aggs could have special characters in their name.
              // The below allows them to edit their name if required, while restricting them only to the aggs allowed special characters.
              (!customerDetails.customerFirstName.match(INPUT_REGEX_NAME) &&
                e.target.value.match(INPUT_REGEX_NAME_SPECIAL_CHARS))
            ) {
              updateCustomerDetails({
                customerFirstName: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={() => {
            showValidation('customerFirstName');
          }}
          onFocus={trackFormTextFieldFocus('Customer first name')}
        />
      </QuestionField>
      <QuestionField
        question={questions.customerLastName}
        errorText={getError('customerLastName')}>
        <NameInput
          id="customerLastName"
          placeholder={questions.customerLastName.placeholder}
          maxLength={30}
          value={customerDetails.customerLastName}
          onChange={(e) => {
            if (
              e.target.value.match(INPUT_REGEX_NAME) ||
              // Users from aggs could have special characters in their name.
              // The below allows them to edit their name if required, while restricting them only to the aggs allowed special characters.
              (!customerDetails.customerLastName.match(INPUT_REGEX_NAME) &&
                e.target.value.match(INPUT_REGEX_NAME_SPECIAL_CHARS))
            ) {
              updateCustomerDetails({
                customerLastName: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={() => {
            showValidation('customerLastName');
          }}
          onFocus={trackFormTextFieldFocus('Customer last name')}
        />
      </QuestionField>
      <QuestionField question={questions.customerDob} errorText={getError('customerDob')}>
        <DateInput
          id="customerDob"
          value={customerDetails.customerDob}
          onChange={(e) =>
            updateCustomerDetails({
              customerDob: e,
            })
          }
          onBlur={() => {
            updateCustomerDetails({
              customerDob: addLeadingZerosToDateValue(customerDetails.customerDob),
            });
            showValidation('customerDob');
          }}
          onFocus={trackFormTextFieldFocus('Customer DOB')}
        />
      </QuestionField>
      <QuestionField
        question={questions.customerEmail}
        errorText={getError('customerEmail')}>
        <TextInput
          id="customerEmail"
          placeholder={questions.customerEmail.placeholder}
          maxLength={254}
          value={customerDetails.customerEmail}
          onChange={(e) => {
            updateCustomerDetails({
              customerEmail: e.target.value.replaceAll(' ', ''),
            });
          }}
          onBlur={() => showValidation('customerEmail')}
          onFocus={trackFormTextFieldFocus('Customer email')}
          inputMode="email"
        />
      </QuestionField>
      <QuestionField
        question={questions.customerTelephone}
        errorText={getError('customerTelephone')}>
        <NameInput
          id="customerTelephone"
          placeholder={questions.customerTelephone.placeholder}
          maxLength={11}
          value={customerDetails.customerTelephone}
          onChange={(e) => {
            updateCustomerDetails({
              customerTelephone: e.target.value,
            });
          }}
          numbersOnly
          onBlur={() => showValidation('customerTelephone')}
          onFocus={trackFormTextFieldFocus('Customer telephone')}
          inputMode="tel"
        />
      </QuestionField>
      <AddressForm
        addressDetails={customerDetails}
        updateAddressDetails={updateCustomerDetails}
        formValidation={{ showValidation, getError }}
      />
      <FormFooter
        contentColumns={{ desktop: 6, tabletLandscape: 6 }}
        moveNextButton={{
          text: next_button_text,
          onClick: () => trackTextButtonClick(PageTitle.AboutYou, 'Submit details'),
        }}
        backButton={{
          onClick: () => {
            moveBack();
            trackTextButtonClick(PageTitle.AboutYou, 'Move back');
          },
        }}
        pageTitle={PageTitle.AboutYou}
      />
    </form>
  );
};

export default AboutYouForm;
