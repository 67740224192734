import { ButtonStyle } from '@rsa-digital/evo-shared-components/components/Button/styles';
import AddressInput from '@rsa-digital/evo-shared-components/components/Form/AddressInput';
import {
  FindAddressButton,
  FindAddressGridItem,
  PostcodeLookupGridItem,
} from '@rsa-digital/evo-shared-components/components/Form/AddressInput/PostcodeSearch/styles';
import { gridItemPropsOverride } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import styled from 'styled-components';

export const AddressInputStyled = styled(AddressInput)`
  ${FindAddressButton} {
    margin-top: 0;

    &${ButtonStyle} {
      &[data-variant='secondary'] {
        font-size: 1rem; /* 16px */
        line-height: 1.5rem; /* 24px */
        font-weight: normal;
      }
    }
  }

  ${PostcodeLookupGridItem} {
    ${gridItemPropsOverride(
      { desktop: 4, tabletLandscape: 3, tabletPortrait: 3, mobile: 1.925 },
      { desktop: 6, tabletLandscape: 6, tabletPortrait: 6, mobile: 4 }
    )}
  }

  ${FindAddressGridItem} {
    ${gridItemPropsOverride(
      { desktop: 2, tabletLandscape: 3, tabletPortrait: 3, mobile: 2.075 },
      { desktop: 6, tabletLandscape: 6, tabletPortrait: 6, mobile: 4 }
    )}
  }
`;
